import server from './server'
import { getToken } from '@/utils/token'
import qs from 'qs'

export const getInfo = () => {
  const token = getToken()
  return server.requestPost(
    'stores/broker/stox_user/info',
    qs.stringify({
      token,
    })
  )
}
export const getSubInfo = (bid) => {
  const token = getToken()
  return server.requestPost(
    'stores/broker/subaccount/wallet_cash',
    qs.stringify({
      token,
      bid,
    })
  )
}

export const getInfoQuery = (token) => {
  return server.requestPost(
    'stores/tdd/stox_user/info',
    qs.stringify({
      token,
    })
  )
}

export const getMessages = (curpage, page_size, unread = 0) => {
  const token = getToken()
  return server.requestGet('stores/tdd/stox_member/messages', {
    token,
    curpage,
    page_size,
    unread,
  })
}

/**
 * 个人中心推荐投资项目列表
 * @returns
 */
export const getRecommend = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_project_member/recommend_list', {
    token,
  })
}

/**
 * 个人中心推荐投资项目列表
 * @returns
 */
export const getProject = () => {
  const token = getToken()
  return server.requestGet('/stores/broker/broker_sgtox_project/order_list', {
    token,
  })
}

export const getDocuments = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/document_list', {
    token,
  })
}

export const getCards = () => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/bank_card_list', {
    token,
  })
}

export const getConfigData = () => {
  const token = getToken()
  return server.requestPostJson('/stores/broker/config_data/info', {
    token,
  })
}

export const getJyMinxi = (curpage, page, currency, category) => {
  const token = getToken()
  return server.requestPost(
    '/stores/tdd/stox_user/transaction_record',
    qs.stringify({
      token,
      curpage,
      page,
      currency,
      category,
    })
  )
}
export const getsubJyMinxi = (bid, curpage, page, currency, category) => {
  const token = getToken()
  return server.requestPost(
    '/stores/broker/subaccount/transaction_record',
    qs.stringify({
      token,
      bid,
      curpage,
      page,
      currency,
      category,
    })
  )
}

/**
 * 添加银行卡
 * @param {*} bank_id
 * @param {*} bank_name
 * @param {*} bank_swift_code
 * @param {*} bank_region
 * @param {*} bank_card_number
 * @param {*} bank_cardholder_name
 * @param {*} file
 * @returns
 */
export const addBandCard = (data) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/add_bank_card?token=${token}`,
    data
  )
}

export const editBandCard = (data) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/update_bank_card?token=${token}`,
    data
  )
}

export const delBandCard = (bank_guid) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/delete_bank_card?token=${token}`,
    qs.stringify({
      bank_guid,
    })
  )
}

export const getBandCardInfo = (bank_guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/bank_card_info?', {
    token,
    bank_guid,
  })
}

export const getMessageInfo = (message_guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_member/message_info', {
    token,
    message_guid,
  })
}

export const readMessage = (type, message_guid) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_member/message_read?token=${token}`,
    qs.stringify({
      type,
      message_guid,
    })
  )
}

export const sendCode = (country_code, mobile) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/stox_member/send_change_sms?token=${token}`,
    qs.stringify({
      country_code,
      mobile,
    })
  )
}

/**
 * 修改手机
 * @param {*} country_code
 * @param {*} mobile
 * @param {*} code
 * @param {*} password
 * @returns
 */
export const changeMobile = (country_code, mobile, code, password) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/stox_member/change_mobile?token=${token}`,
    qs.stringify({
      country_code,
      mobile,
      code,
      password,
    })
  )
}

/**
 * 修改密码
 * @param {*} old_password
 * @param {*} new_password
 * @param {*} password_confirm
 * @returns
 */
export const changePassword = (
  old_password,
  new_password,
  password_confirm
) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/stox_member/change_passwd?token=${token}`,
    qs.stringify({
      old_password,
      new_password,
      password_confirm,
    })
  )
}

/**
 * 项目的订单统计百分比数据
 * @param {*} guid
 * @returns
 */
export const getProjectOrderAmount = (guid) => {
  const token = getToken()
  return server.requestGet('/stores/tdd/stox_project/order_amount?', {
    token,
    guid,
  })
}

/**
 * 项目详情页面-去投资查询余额代金券税费比例
 * @param {*} guid
 * @returns
 */
export const getQueryBalanceVoucher = () => {
  const token = getToken()
  return server.requestPost(
    '/stores/tdd/stox_project_member/query_balance_voucher',
    qs.stringify({
      token,
    })
  )
}

/**
 * 项目详情页面-投资数据提交保存
 * @returns
 */
export const pushProject = (project_guid, total_purchase, pw) => {
  const token = getToken()
  return server.requestPost(
    `/stores/tdd/stox_project_member/purchase_save?token=${token}`,
    qs.stringify({
      project_guid,
      total_purchase,
      pw,
    })
  )
}

export const getinvested = (order_guid) => {
  const token = getToken()
  return server.requestPost(
    `stores/tdd/stox_member/get_order_detail`,
    qs.stringify({
      token,
      order_guid,
    })
  )
}
export const getAiyunkycUrl = (merchantUserId, metaInfo, returnUrl) => {
  return server.requestPost(
    `stores/tdd/aliyunkyc/get_facelive_url`,
    qs.stringify({
      merchantUserId,
      metaInfo,
      returnUrl,
    })
  )
}

export const getFaceResult = (merchantBizId, transactionId) => {
  return server.requestPost(
    `stores/tdd/aliyunkyc/get_face_check_result`,
    qs.stringify({
      merchantBizId,
      transactionId,
    })
  )
}

export const get_domain_url = () => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/broker/get_domain_url`,
    qs.stringify({
      token,
    })
  )
}

export const getSubaccountList = (curpage, page) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/subaccount/list`,
    qs.stringify({
      token,
      curpage,
      page,
    })
  )
}
export const getSub1accountList = (curpage, page) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/broker_subaccount/list`,
    qs.stringify({
      token,
      curpage,
      page,
    })
  )
}

export const addSubaccount = (name, email, country_code, mobile, bid) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/subaccount/add`,
    qs.stringify({
      token,
      name,
      email,
      country_code,
      mobile,
      bid,
    })
  )
}
export const addSubaccountOne = (
  name,
  email,
  country_code,
  mobile,
  broker_code
) => {
  const token = getToken()
  return server.requestPost(
    `/stores/broker/broker_subaccount/add`,
    qs.stringify({
      token,
      name,
      email,
      country_code,
      mobile,
      broker_code,
    })
  )
}
