import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import cookie from 'js-cookie'

import Web3 from 'web3'
Vue.prototype.Web3 = Web3
Vue.use(VueI18n)
Vue.prototype.cookie = cookie
// 设置 cookie 工具函数
Vue.prototype.set_cookie = (name, data, expires) => {
  cookie.set(name, data, {
    expires: expires,
  })
}

const i18n = {
  locale: cookie.get('lang') || 'zh', // 语言标识,第一次登录默认是中文
  messages: {
    zh: require('@/assets/language/local_zh'), // 汉语
    en: require('@/assets/language/local_en'), // 英语
  },
}

// import vconsole from 'vconsole'

// if (location.origin.indexOf('uat') >= 0) {
//   new vconsole()
// }
//new vconsole();
import 'vant/lib/index.css'
import ElementUI from 'element-ui'

import { global } from './utils/global'

import { Uploader } from 'vant'
Vue.use(Uploader)
import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs
Vue.prototype.global = global
const formatter = new Intl.NumberFormat('en-NZ', {
  minimumFractionDigits: 2,
})

Vue.filter('numFormat', (str) => {
  if (Number(str) < 1000) {
    return Number(str).toFixed(2)
  }
  return formatter.format(str)
})
// import 'lib-flexible'

import VueBus from 'vue-bus'
Vue.use(VueBus)

import('./utils/rem.js')

import 'element-ui/lib/theme-chalk/index.css'
import './assets/yk_global.css'

Vue.config.productionTip = false
//Vue.use(ElementUI,VueI18n);
Vue.use(ElementUI)
import { Icon } from 'vant'

Vue.use(Icon)

function fanTran() {
  var fanStart = true

  var fanTimer = setInterval(function () {
    if (fanStart) {
      if (sessionStorage.getItem('cnType') == 'fan') {
        zh_tran('t')
      } else {
        zh_tran('s')
      }
    }
  }, 100)

  setTimeout(function () {
    fanStart = false
    clearInterval(fanTimer)
  }, 3000)
}

router.beforeEach((to, from, next) => {
  console.log('to', to)
  // if(to.path == '/logincode'){
  //   console.log('logincodepage');
  // }else{
  //   fanTran();
  // }

  fanTran()
  next()
})

$('body').click(function () {
  fanTran()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
