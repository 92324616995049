import { getPost, getGet } from '../api/apiList'
import qs from 'qs'

var global = {
  isFan: false,
  city: [
    { key: '中国 (+86)', value: '86' },
    { key: '新加坡(+65)', value: '65' },
    { key: '中国香港(+852)', value: '852' },
    { key: '阿布哈兹 (+7)', value: '7' },
    { key: '阿富汗 (+93)', value: '93' },
    { key: '阿尔巴尼亚 (+355)', value: '355' },
    { key: '阿尔及利亚 (+213)', value: '213' },
    { key: '美属萨摩亚 (+1684)', value: '1684' },
    { key: '安道尔 (+376)', value: '376' },
    { key: '阿根廷 (+54)', value: '54' },
    { key: '亚美尼亚 (+374)', value: '374' },
    { key: '阿鲁巴 (+297)', value: '297' },
    { key: '澳大利亚 (+61)', value: '61' },
    { key: '奥地利 (+43)', value: '43' },
    { key: '阿塞拜疆 (+994)', value: '994' },
    { key: '巴哈马 (+1242)', value: '1242' },
    { key: '巴林 (+973)', value: '973' },
    { key: '孟加拉国 (+880)', value: '880' },
    { key: '巴巴多斯 (+1246)', value: '1246' },
    { key: '白俄罗斯 (+375)', value: '375' },
    { key: '比利时 (+32)', value: '32' },
    { key: '玻利维亚 (+591)', value: '591' },
    { key: '波斯尼亚和黑塞哥维那 (+387)', value: '387' },
    { key: '博茨瓦纳 (+267)', value: '267' },
    { key: '巴西 (+55)', value: '55' },
    { key: '文莱达鲁萨兰国 (+673)', value: '673' },
    { key: '保加利亚 (+359)', value: '359' },
    { key: '布基纳法索 (+226)', value: '226' },
    { key: '布隆迪 (+257)', value: '257' },
    { key: '佛得角 (+238)', value: '238' },
    { key: '柬埔寨 (+855)', value: '855' },
    { key: '加拿大 (+1)', value: '1' },
    { key: '中非共和国 (+236)', value: '236' },
    { key: '乍得 (+235)', value: '235' },
    { key: '智利 (+56)', value: '56' },
    { key: '哥伦比亚 (+57)', value: '57' },
    { key: '科摩罗 (+269)', value: '269' },
    { key: '刚果 (+242)', value: '242' },
    { key: '刚果（民主共和国） (+243)', value: '243' },
    { key: '库克群岛(+682)', value: '682' },
    { key: '哥斯达黎加(+506)', value: '506' },
    { key: '科特迪瓦(+225)', value: '225' },
    { key: '克罗地亚(+385)', value: '385' },
    { key: '古巴(+53)', value: '53' },
    { key: '库拉索(+599)', value: '599' },
    { key: '塞浦路斯(+357)', value: '357' },
    { key: '捷克共和国(+420)', value: '420' },
    { key: '丹麦(+45)', value: '45' },
    { key: '吉布提(+253)', value: '253' },
    { key: '多米尼克(+1767)', value: '1767' },
    { key: '多米尼加共和国(+1)', value: '1' },
    { key: '厄瓜多尔(+593)', value: '593' },
    { key: '埃及(+20)', value: '20' },
    { key: '萨尔瓦多(+503)', value: '503' },
    { key: '赤道几内亚(+240)', value: '240' },
    { key: '厄立特里亚(+291)', value: '291' },
    { key: '爱沙尼亚(+372)', value: '372' },
    { key: '斯威士兰(+268)', value: '268' },
    { key: '埃塞俄比亚(+251)', value: '251' },
    { key: '福克兰群岛(+500)', value: '500' },
    { key: '法罗群岛(+298)', value: '298' },
    { key: '斐济(+679)', value: '679' },
    { key: '芬兰(+358)', value: '358' },
    { key: '法国(+33)', value: '33' },
    { key: '法属圭亚那(+594)', value: '594' },
    { key: '法属波利尼西亚(+689)', value: '689' },
    { key: '加蓬(+241)', value: '241' },
    { key: '冈比亚(+220)', value: '220' },
    { key: '佐治亚州(+995)', value: '995' },
    { key: '德国(+49)', value: '49' },
    { key: '加纳(+233)', value: '233' },
    { key: '直布罗陀(+350)', value: '350' },
    { key: '希腊(+30)', value: '30' },
    { key: '格陵兰岛(+299)', value: '299' },
    { key: '格林纳达(+1473)', value: '1473' },
    { key: '瓜德罗普岛(+590)', value: '590' },
    { key: '关岛(+1)', value: '1' },
    { key: '危地马拉(+502)', value: '502' },
    { key: '根西岛(+44)', value: '44' },
    { key: '几尼(+224)', value: '224' },
    { key: '几内亚比绍(+245)', value: '245' },
    { key: '圭亚那(+592)', value: '592' },
    { key: '海地(+509)', value: '509' },
    { key: '洪都拉斯(+504)', value: '504' },
    { key: '匈牙利(+36)', value: '36' },
    { key: '冰岛(+354)', value: '354' },
    { key: '印度(+91)', value: '91' },
    { key: '印度尼西亚(+62)', value: '62' },
    { key: '国际网络(+882)', value: '882' },
    { key: '伊朗(+98)', value: '98' },
    { key: '伊拉克(+964)', value: '964' },
    { key: '爱尔兰(+353)', value: '353' },
    { key: '马恩岛(+44)', value: '44' },
    { key: '以色列(+972)', value: '972' },
    { key: '意大利(+39)', value: '39' },
    { key: '牙买加(+1)', value: '1' },
    { key: '日本(+81)', value: '81' },
    { key: '运动衫(+44)', value: '44' },
    { key: '乔丹(+962)', value: '962' },
    { key: '哈萨克斯坦(+7)', value: '7' },
    { key: '肯尼亚(+254)', value: '254' },
    { key: '基里巴斯(+686)', value: '686' },
    { key: '大韩民国(+82)', value: '82' },
    { key: '科索沃(+383)', value: '383' },
    { key: '科威特(+965)', value: '965' },
    { key: '吉尔吉斯斯坦(+996)', value: '996' },
    { key: '老挝人民民主共和国(+856)', value: '856' },
    { key: '拉脱维亚(+371)', value: '371' },
    { key: '黎巴嫩(+961)', value: '961' },
    { key: '莱索托(+266)', value: '266' },
    { key: '利比里亚(+231)', value: '231' },
    { key: '利比亚(+218)', value: '218' },
    { key: '列支敦士登(+423)', value: '423' },
    { key: '立陶宛(+370)', value: '370' },
    { key: '卢森堡(+352)', value: '352' },
    { key: '澳门(+853)', value: '853' },
    { key: '马达加斯加(+261)', value: '261' },
    { key: '马拉维(+265)', value: '265' },
    { key: '马来西亚(+60)', value: '60' },
    { key: '马尔代夫(+960)', value: '960' },
    { key: '马里(+223)', value: '223' },
    { key: '马耳他(+356)', value: '356' },
    { key: '马提尼克岛(+596)', value: '596' },
    { key: '毛里塔尼亚(+222)', value: '222' },
    { key: '毛里求斯(+230)', value: '230' },
    { key: '马约特(+262)', value: '262' },
    { key: '墨西哥(+52)', value: '52' },
    { key: '密克罗尼西亚联邦(+691)', value: '691' },
    { key: '摩尔多瓦(+373)', value: '373' },
    { key: '摩纳哥(+377)', value: '377' },
    { key: '蒙古(+976)', value: '976' },
    { key: '黑山(+382)', value: '382' },
    { key: '蒙特塞拉特(+1664)', value: '1664' },
    { key: '摩洛哥(+212)', value: '212' },
    { key: '莫桑比克(+258)', value: '258' },
    { key: '缅甸(+95)', value: '95' },
    { key: '纳米比亚(+264)', value: '264' },
    { key: '瑙鲁(+674)', value: '674' },
    { key: '尼泊尔(+977)', value: '977' },
    { key: '荷兰(+31)', value: '31' },
    { key: '新喀里多尼亚(+687)', value: '687' },
    { key: '新西兰(+64)', value: '64' },
    { key: '尼加拉瓜(+505)', value: '505' },
    { key: '尼日尔(+227)', value: '227' },
    { key: '尼日利亚(+234)', value: '234' },
    { key: '诺福克岛(+672)', value: '672' },
    { key: '北马其顿(+389)', value: '389' },
    { key: '北塞浦路斯(+90)', value: '90' },
    { key: '挪威(+47)', value: '47' },
    { key: '阿曼(+968)', value: '968' },
    { key: '巴基斯坦(+92)', value: '92' },
    { key: '帕劳(+680)', value: '680' },
    { key: '巴拿马(+507)', value: '507' },
    { key: '巴布亚新几内亚(+675)', value: '675' },
    { key: '巴拉圭(+595)', value: '595' },
    { key: '秘鲁(+51)', value: '51' },
    { key: '菲律宾(+63)', value: '63' },
    { key: '波兰(+48)', value: '48' },
    { key: '葡萄牙(+351)', value: '351' },
    { key: '波多黎各(+1)', value: '1' },
    { key: '卡塔尔(+974)', value: '974' },
    { key: '重聚(+262)', value: '262' },
    { key: '罗马尼亚(+40)', value: '40' },
    { key: '俄罗斯联邦(+7)', value: '7' },
    { key: '卢旺达(+250)', value: '250' },
    { key: '圣赫勒拿(+290)', value: '290' },
    { key: '圣基茨和尼维斯(+1869)', value: '1869' },
    { key: '圣卢西亚(+1758)', value: '1758' },
    { key: '圣皮埃尔和密克隆(+508)', value: '508' },
    { key: '圣文森特和格林纳丁斯(+1784)', value: '1784' },
    { key: '萨摩亚(+685)', value: '685' },
    { key: '圣多美和普林西比(+239)', value: '239' },
    { key: '沙特阿拉伯(+966)', value: '966' },
    { key: '塞内加尔(+221)', value: '221' },
    { key: '塞舌尔(+248)', value: '248' },
    { key: '塞拉利昂(+232)', value: '232' },
    { key: '荷属圣马丁(+1721)', value: '1721' },
    { key: '斯洛伐克(+421)', value: '421' },
    { key: '斯洛文尼亚(+386)', value: '386' },
    { key: '所罗门群岛(+677)', value: '677' },
    { key: '索马里(+252)', value: '252' },
    { key: '南非(+27)', value: '27' },
    { key: '南奥塞梯(+7)', value: '7' },
    { key: '南苏丹(+211)', value: '211' },
    { key: '西班牙(+34)', value: '34' },
    { key: '斯里兰卡(+94)', value: '94' },
    { key: '苏丹(+249)', value: '249' },
    { key: '苏里南(+597)', value: '597' },
    { key: '瑞典(+46)', value: '46' },
    { key: '瑞士(+41)', value: '41' },
    { key: '叙利亚(+963)', value: '963' },
    { key: '中国台湾(+886)', value: '886' },
    { key: '塔吉克斯坦(+992)', value: '992' },
    { key: '坦桑尼亚(+255)', value: '255' },
    { key: '泰国(+66)', value: '66' },
    { key: '东帝汶(+670)', value: '670' },
    { key: '多哥(+228)', value: '228' },
    { key: '汤加(+676)', value: '676' },
    { key: '特立尼达和多巴哥(+1868)', value: '1868' },
    { key: '突尼斯(+216)', value: '216' },
    { key: '土耳其(+90)', value: '90' },
    { key: '土库曼斯坦(+993)', value: '993' },
    { key: '特克斯和凯科斯群岛(+1649)', value: '1649' },
    { key: '乌干达(+256)', value: '256' },
    { key: '乌克兰(+380)', value: '380' },
    { key: '阿拉伯联合酋长国(+971)', value: '971' },
    { key: '大不列颠联合王国(+44)', value: '44' },
    { key: '乌拉圭(+598)', value: '598' },
    { key: '乌兹别克斯坦(+998)', value: '998' },
    { key: '瓦努阿图(+678)', value: '678' },
    { key: '委内瑞拉(+58)', value: '58' },
    { key: '越南(+84)', value: '84' },
    { key: '维尔京群岛（英国）(+1284)', value: '1284' },
    { key: '沃利斯和富图纳(+681)', value: '681' },
    { key: '也门(+967)', value: '967' },
    { key: '赞比亚(+260)', value: '260' },
    { key: '津巴布韦(+263)', value: '263' },
  ],
  bank: [
    { value: '新加坡银行有限公司', SwiftCode: 'INGPSGSG', original: '新加坡' },
    { value: '大华银行有限公司', SwiftCode: 'UOVBSGSG', original: '新加坡' },
    { value: '星展银行有限公司', SwiftCode: 'DBSSSGSG', original: '新加坡' },
    { value: '华侨银行有限公司', SwiftCode: 'OCBCSGSG', original: '新加坡' },

    { value: '中国银行股份有限公司', SwiftCode: 'BKCHSGSG', original: '中国' },
    { value: '中国工商银行', SwiftCode: 'ICBKSGSG', original: '中国' },
    { value: '交通银行新加坡分行', SwiftCode: 'COMMSGSG', original: '中国' },
    {
      value: '上海浦东发展银行股份有限公司',
      SwiftCode: 'SPDBSGSG',
      original: '中国',
    },
    {
      value: '中国农业银行股份有限公司新加坡分行',
      SwiftCode: 'ABOCSGSG',
      original: '中国',
    },
    { value: '招商银行新加坡分行', SwiftCode: 'CMBCSGSGPBD', original: '中国' },
    {
      value: '中国建设银行股份有限公司新加坡分行',
      SwiftCode: 'PCBCSGSGCIS',
      original: '中国',
    },
    {
      value: '中信银行国际有限公司新加坡分行',
      SwiftCode: 'KWHKSGSG',
      original: '中国',
    },
    {
      value: '中国信托银行股份有限公司新加坡分行',
      SwiftCode: 'CTCBSGSG',
      original: '中国',
    },
    { value: '兴业银行', SwiftCode: 'SOGESGSG', original: '中国' },
    { value: '东亚银行有限公司', SwiftCode: 'BEASSGSG', original: '中国' },
    { value: '台湾银行', SwiftCode: 'BKTWSGSG', original: '中国' },
    {
      value: '台湾土地银行新加坡分行',
      SwiftCode: 'LBOTSGSG',
      original: '中国',
    },

    { value: '联昌银行', SwiftCode: 'CIBBSGSG', original: '马来西亚' },
    {
      value: '马来亚银行有限公司',
      SwiftCode: 'MBBESGSG',
      original: '马来西亚',
    },
    { value: '新加坡丰隆银行', SwiftCode: 'HLBBSGSG', original: '马来西亚' },
    { value: '兴业银行有限公司', SwiftCode: 'RHBBSGSG', original: '马来西亚' },
    {
      value: '韩国开发银行新加坡分行',
      SwiftCode: 'KODBSGSG',
      original: '韩国',
    },
    { value: '三菱日联银行有限公司', SwiftCode: 'BOTKSGSX', original: '日本' },
    { value: '三井住友银行', SwiftCode: 'SMBCSGSG', original: '日本' },
    { value: '印度银行', SwiftCode: 'IDIBSGSG', original: '印度' },
    { value: '印度国家银行', SwiftCode: 'SBINSGSG', original: '印度' },
    { value: '曼谷银行股份有限公司', SwiftCode: 'BKKBSGSG', original: '泰国' },
    {
      value: '菲律宾国家银行-新加坡',
      SwiftCode: 'PNBMSGSG',
      original: '菲律宾',
    },
    {
      value: '卡塔尔国家银行 (QPSC)',
      SwiftCode: 'QNBASGSG',
      original: '卡塔尔',
    },
    {
      value: '沙特国家银行新加坡分行',
      SwiftCode: 'NCBKSGSG',
      original: '沙特',
    },

    {
      value: '美国银行（新加坡）有限公司',
      SwiftCode: 'BOFASG3XJVC',
      original: '美国',
    },
    {
      value: '摩根大通（海）有限公司',
      SwiftCode: 'CMSESGSG',
      original: '美国',
    },
    {
      value: '花旗银行新加坡有限公司',
      SwiftCode: 'CITISGSL',
      original: '美国',
    },
    {
      value: '渣打银行（新加坡）有限公司',
      SwiftCode: 'SCBLSG22',
      original: '美国',
    },
    {
      value: '汇丰银行（新加坡）有限公司',
      SwiftCode: 'HSBCSGS2',
      original: '英国',
    },
    { value: '巴克莱银行', SwiftCode: 'BARCSGSG', original: '英国' },
    { value: '加拿大皇家银行', SwiftCode: 'ROYCSGSGGSS', original: '加拿大' },
    { value: '加拿大帝国商业银行', SwiftCode: 'CIBCSGSG', original: '加拿大' },
    {
      value: '法国巴黎银行-新加坡分行',
      SwiftCode: 'BNPASGSGGCG',
      original: '法国 ',
    },
    {
      value: '德国商业银行新加坡分行',
      SwiftCode: 'COBASGSX',
      original: '德国 ',
    },
    { value: '德意志银行', SwiftCode: 'DEUTSGSR', original: '德国 ' },
    { value: '澳大利亚联邦银行', SwiftCode: 'CTBASGSG', original: '澳大利亚' },
    {
      value: '瑞士信贷银行新加坡分行',
      SwiftCode: 'CRESSGSG',
      original: '瑞士',
    },
    { value: '荷兰国际银行', SwiftCode: 'INGBSGSG', original: '荷兰' },
  ],
  date(format, timestamp) {
    var a,
      jsdate = timestamp ? new Date(timestamp * 1000) : new Date()
    var pad = function (n, c) {
      if ((n = n + '').length < c) {
        return new Array(++c - n.length).join('0') + n
      } else {
        return n
      }
    }
    var ret
    var txt_weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
    var txt_ordin = {
      1: 'st',
      2: 'nd',
      3: 'rd',
      21: 'st',
      22: 'nd',
      23: 'rd',
      31: 'st',
    }
    var txt_months = [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    var f = {
      // Day
      d: function () {
        return pad(f.j(), 2)
      },
      D: function () {
        return f.l().substr(0, 3)
      },
      j: function () {
        return jsdate.getDate()
      },
      l: function () {
        return txt_weekdays[f.w()]
      },
      N: function () {
        return f.w() + 1
      },
      S: function () {
        return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'
      },
      w: function () {
        return jsdate.getDay()
      },
      z: function () {
        return ((jsdate - new Date(jsdate.getFullYear() + '/1/1')) / 864e5) >> 0
      },

      // Week
      W: function () {
        var a = f.z(),
          b = 364 + f.L() - a
        var nd2,
          nd = (new Date(jsdate.getFullYear() + '/1/1').getDay() || 7) - 1
        if (b <= 2 && (jsdate.getDay() || 7) - 1 <= 2 - b) {
          return 1
        } else {
          if (a <= 2 && nd >= 4 && a >= 6 - nd) {
            nd2 = new Date(jsdate.getFullYear() - 1 + '/12/31')
            return date('W', Math.round(nd2.getTime() / 1000))
          } else {
            return (1 + (nd <= 3 ? (a + nd) / 7 : (a - (7 - nd)) / 7)) >> 0
          }
        }
      },

      // Month
      F: function () {
        return txt_months[f.n()]
      },
      m: function () {
        return pad(f.n(), 2)
      },
      M: function () {
        return f.F().substr(0, 3)
      },
      n: function () {
        return jsdate.getMonth() + 1
      },
      t: function () {
        var n
        if ((n = jsdate.getMonth() + 1) == 2) {
          return 28 + f.L()
        } else {
          if ((n & 1 && n < 8) || (!(n & 1) && n > 7)) {
            return 31
          } else {
            return 30
          }
        }
      },

      // Year
      L: function () {
        var y = f.Y()
        return !(y & 3) && (y % 1e2 || !(y % 4e2)) ? 1 : 0
      },
      //o not supported yet
      Y: function () {
        return jsdate.getFullYear()
      },
      y: function () {
        return (jsdate.getFullYear() + '').slice(2)
      },

      // Time
      a: function () {
        return jsdate.getHours() > 11 ? 'pm' : 'am'
      },
      A: function () {
        return f.a().toUpperCase()
      },
      B: function () {
        // peter paul koch:
        var off = (jsdate.getTimezoneOffset() + 60) * 60
        var theSeconds =
          jsdate.getHours() * 3600 +
          jsdate.getMinutes() * 60 +
          jsdate.getSeconds() +
          off
        var beat = Math.floor(theSeconds / 86.4)
        if (beat > 1000) beat -= 1000
        if (beat < 0) beat += 1000
        if (String(beat).length == 1) beat = '00' + beat
        if (String(beat).length == 2) beat = '0' + beat
        return beat
      },
      g: function () {
        return jsdate.getHours() % 12 || 12
      },
      G: function () {
        return jsdate.getHours()
      },
      h: function () {
        return pad(f.g(), 2)
      },
      H: function () {
        return pad(jsdate.getHours(), 2)
      },
      i: function () {
        return pad(jsdate.getMinutes(), 2)
      },
      s: function () {
        return pad(jsdate.getSeconds(), 2)
      },
      //u not supported yet

      // Timezone
      //e not supported yet
      //I not supported yet
      O: function () {
        var t = pad(Math.abs((jsdate.getTimezoneOffset() / 60) * 100), 4)
        if (jsdate.getTimezoneOffset() > 0) t = '-' + t
        else t = '+' + t
        return t
      },
      P: function () {
        var O = f.O()
        return O.substr(0, 3) + ':' + O.substr(3, 2)
      },
      //T not supported yet
      //Z not supported yet

      // Full Date/Time
      c: function () {
        return (
          f.Y() +
          '-' +
          f.m() +
          '-' +
          f.d() +
          'T' +
          f.h() +
          ':' +
          f.i() +
          ':' +
          f.s() +
          f.P()
        )
      },
      //r not supported yet
      U: function () {
        return Math.round(jsdate.getTime() / 1000)
      },
    }

    return format.replace(/([a-zA-Z])/g, function (t, s) {
      if (t != s) {
        // escaped
        ret = s
      } else if (f[s]) {
        // a date function exists
        ret = f[s]()
      } else {
        // nothing special
        ret = s
      }
      return ret
    })
  },
  getqiyeinfo() {
    var data2 = {}
    var url = `/stores/tdd/qy_member/bind_info?wxapp_id=10001&token=${this.token}`
    qiyeBind(url, data2).then((res) => {
      if (res.code == 1) {
        this.rzname = res.data.qy_name
        this.rzaddr = res.data.qy_address

        var qystime = this.date('Y-m', res.data.stime)
        var qyetime = this.date('Y-m', res.data.etime)

        sessionStorage.setItem('qystime', qystime)
        sessionStorage.setItem('qyetime', qyetime)

        sessionStorage.setItem('stime', qystime)
        sessionStorage.setItem('etime', qyetime)

        sessionStorage.setItem('qy_guid', res.data.guid)

        sessionStorage.setItem('qy_name', res.data.qy_name)
        sessionStorage.setItem('qy_addr', res.data.qy_address)
        sessionStorage.setItem('qy_conum', res.data.house_num)

        sessionStorage.setItem('qyzq_admin', res.data.is_admin)

        if (
          res.data.ccer_pic == 'null' ||
          res.data.ccer_pic == null ||
          res.data.ccer_pic == ''
        ) {
          sessionStorage.setItem('ccerPic', 0)
        } else {
          sessionStorage.setItem('ccerPic', res.data.ccer_pic)
        }

        this.rzzhouqi = qystime + '至' + qyetime

        this.qyadmin = res.data.is_admin
        this.guid = res.data.guid

        this.qybind = res.data.is_bind
      } else {
        Toast(res.msg)
      }
    })
  },
  fanTran() {
    var fanStart = true

    var fanTimer = setInterval(function () {
      if (fanStart) {
        if (sessionStorage.getItem('cnType') == 'fan') {
          zh_tran('t')
        } else {
          zh_tran('s')
        }
      }
    }, 100)

    setTimeout(function () {
      fanStart = false
      clearInterval(fanTimer)
    }, 3000)
  },
}

export { global }
