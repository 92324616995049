<template>
  <div class="bg">
    <Header :currentNav="0"></Header>

    <div class="fullImg">
      <div v-if="isFan" class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="cursor: pointer">
            <div>
              <img src="../assets/images/index/banner_f.jpg" />
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div><img  src="../assets/images/index/banner.jpg"></div>
          </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>

      <div v-else class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="cursor: pointer">
            <div>
              <img src="../assets/images/index/banner.jpg" />
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div><img  src="../assets/images/index/banner.jpg"></div>
          </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="fullImg">
      <div>
        <img v-if="isFan" src="../assets/images/index/homebar2_f.jpg" />
        <img v-else src="../assets/images/index/homebar2.jpg" />
      </div>
    </div>

    <div class="fullImg" @click="goinvestList" style="cursor: pointer">
      <div>
        <img v-if="isFan" src="../assets/images/index/homebar3_f.jpg" />
        <img v-else src="../assets/images/index/homebar3.jpg" />
      </div>
    </div>

    <div class="fullImg">
      <div>
        <img v-if="isFan" src="../assets/images/index/homebar4_f.jpg" />
        <img v-else src="../assets/images/index/homebar4.jpg" />
      </div>
    </div>
    <div class="fullImg">
      <div>
        <img v-if="isFan" src="../assets/images/index/homebar5_f.jpg" />
        <img v-else src="../assets/images/index/homebar5.jpg" />
      </div>
    </div>

    <div class="fullImg">
      <div>
        <img v-if="isFan" src="../assets/images/index/homebar6_f.jpg" />
        <img v-else src="../assets/images/index/homebar6.jpg" />
      </div>
    </div>

    <div class="qabox">
      <div class="safeW">
        <div class="qatitle">常见问题</div>
        <div class="qacontainer">
          <div class="qaUnit" v-for="(item, index) in qList" :key="index">
            <div class="q" @click="qslide(index)">
              <i></i>
              <div class="arrowdown">
                <i
                  class="el-icon-arrow-up"
                  v-if="qStateList[index] == true"
                ></i>
                <i class="el-icon-arrow-down" v-else></i>
              </div>
              {{ item.article_title }}
            </div>
            <div class="a" v-if="qStateList[index] == true">
              <div v-html="item.article_content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Bottom from '@/components/Bottom'
import { smsCode, loginMobile, getPost, getGet } from '../api/apiList'
import qs from 'qs'
export default {
  components: { Header, Bottom },
  data() {
    return {
      qStateList: [],
      qList: [],
      cntype: sessionStorage.getItem('cnType'),
      isFan: false,
      indexSwipe: {},
    }
  },
  created() {},

  watch: {},

  mounted() {
    var that = this

    if (sessionStorage.getItem('cnType') == 'fan') {
      this.isFan = true
    } else {
      this.isFan = false
    }

    this.$bus.on('busFan', (bus) => {
      if (bus == 'fan') {
        this.isFan = true
      } else if (bus == 'jian') {
        this.isFan = false
      }
      //  this.indexSwipe.update();
    })

    setTimeout(() => {
      this.initSwipe()
    }, 1000)

    //this.qStateList = [];

    this.getIndexQa()
  },
  methods: {
    initSwipe() {
      var that = this
      this.indexSwipe = new Swiper('.swiper-container', {
        autoplay: true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          tap: function (swiper, event) {
            that.$router.push({
              path: '/reg',
            })
          },
          resize: function () {
            setTimeout(() => {
              that.indexSwipe.update()
            }, 100)
          },
        },
      })
    },

    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },

    goinvestList() {
      this.$router.push({
        path: '/investList',
      })
    },

    getIndexQa() {
      let url1 = 'stox_home/index_qa'
      let data1 = {}

      getPost(url1, data1).then((res) => {
        if (res.code == 1) {
          this.qList = res.data

          for (var i = 0; i < res.data.length; i++) {
            this.qStateList.push(false)
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    qslide(i) {
      // console.log('e',e);
      for (var j = 0; j < this.qList.length; j++) {
        if (j == i) {
        } else {
          this.qStateList[j] = false
        }
      }
      this.qStateList[i] = !this.qStateList[i]
      this.$forceUpdate()
      console.log('qStateList', this.qStateList)
    },
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.bg {
  width: 100%;
}

.fullImg {
  position: relative;
  min-width: 1280px;
  overflow-x: hidden;
  margin: 0 auto;
  > div {
    position: relative;
    width: 1920px;
    left: 50%;
    margin-left: -960px;
    img {
      width: 1920px;
      vertical-align: bottom;
    }
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container > .swiper-wrapper > .swiper-slide {
  text-align: center;
  font-size: 18px;
  position: relative;

  width: 100%;
  height: 725px;
}

.swiper-pagination {
  bottom: 50px;
}

// .swiper-pagination-bullet-active{
//   background:#0f0 !important;
// }

.qabox {
  background: #fafafa;
  background: url('../assets/images/index/faqbg.png') no-repeat;
  background-position: right 0;
}

.safeW {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 70px 0;
}
.qatitle {
  color: #2f2f2f;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 94px;
}

.qaUnit {
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #ccc;
  margin-bottom: 20px;
  > .q {
    font-size: 20px;
    color: #000;
    padding: 20px;
    padding-left: 40px;
    padding-right: 60px;
    position: relative;
    cursor: pointer;
    > i {
      position: absolute;
      left: 20px;
      top: 23px;
      width: 4px;
      height: 22px;
      background: #da3c3f;
    }
    > .arrowdown {
      position: absolute;
      right: 20px;
      top: 23px;
      i {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  > .a {
    border-top: 1px solid #dcddde;
    padding: 20px;
    font-size: 16px;
    word-break: break-all;
    color: #666;
    > .more {
      text-align: right;
      text-decoration: underline;
      color: #6b89e7;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
