<template>
  <div>
    <div class="fixTop">
      <div class="safeW">
        <div class="tL">
          <div
            class="bigLogo"
            v-if="bigScreen"
            style="cursor: pointer"
            @click="goHome"
          >
            <img src="../assets/images/xuexi/logo.png" />
          </div>
          <div class="tLflex">
            <div
              class="l"
              v-if="!bigScreen"
              style="cursor: pointer"
              @click="goHome"
            >
              <img src="../assets/images/xuexi/logo.png" />
            </div>
          </div>
        </div>

        <div class="tR" v-if="isLogin">
          <div class="flex">
            <div class="cmbi">
              <span v-if="categ == 3">代理商</span>
              <span v-else>代理商子账户</span>
              ：{{ brokerCode }}
            </div>
            <div
              style="margin-right: 27px; cursor: pointer"
              @click="
                $router.push({
                  name: 'wallet',
                })
              "
            >
              <img
                class="img50"
                src="../assets/images/mycenter/qianbao.png"
                alt=""
              />
            </div>
            <el-dropdown placement="bottom-end">
              <span class="el-dropdown-link">
                <div
                  class="m"
                  @click="
                    $router.push({
                      name: 'home',
                    })
                  "
                >
                  <img
                    class="img50"
                    src="../assets/images/mycenter/people.png"
                    alt=""
                  />
                </div>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div class="flexBox">
                    <div style="margin-right: 21px">
                      <img
                        class="img50"
                        style="margin-top: 15px"
                        src="../assets/images/mycenter/people.png"
                        alt=""
                      />
                    </div>
                    <div>
                      <div class="name">
                        {{ email }}
                      </div>
                      <div class="info">
                        <span v-if="categ == 3">代理商</span>
                        <span v-else>代理商子账户</span>
                      </div>
                    </div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div
                    class="droItem"
                    @click="
                      $router.push({
                        name: 'setting',
                      })
                    "
                  >
                    <img
                      class="setimg"
                      src="../assets/images/mycenter/set.png"
                      alt=""
                    />
                    <span>设置</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item>
                  <div class="droItem" @click="logout">
                    <img
                      class="outimg"
                      style="margin-right: 13x"
                      src="../assets/images/mycenter/out.png"
                      alt=""
                    />
                    <span style="color: #ce0d1b">退出登录</span>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { getInfo } from '@/api/home'
export default {
  name: 'NavButton',

  props: ['currentNav'],

  data() {
    return {
      bigScreen: true,
      token: sessionStorage.getItem('token'),
      isLogin: false,
      email: '',
      categ: '',
      fill: '#333',
      personalok: 0,

      isFan: false,
      brokerCode: '',
    }
  },
  mounted() {
    const isFox = navigator.userAgent.indexOf('Firefox') > -1 ? true : false
    console.log($(window).width())
    var sW = $(window).width()
    if (sW >= 1000) {
      this.bigScreen = true
    } else {
      this.bigScreen = false
    }

    // if (isFox) {
    //   if (sW <= 1600) {
    //     $('.bigLogo').css('left', '-150px')
    //   }
    // }
    this.change()
    this.init()

    if (sessionStorage.getItem('cnType') == 'fan') {
      this.isFan = true
    } else {
      this.isFan = false
    }
  },
  methods: {
    ...mapActions(['asyncSetMessage']),
    handleCommand(command) {
      if (command === 'en') {
        this.lang_change()
      } else {
        if (this.isFan) {
          this.toJan()
        } else {
          this.toFan()
        }
      }
    },
    toFan() {
      this.isFan = true
      sessionStorage.setItem('cnType', 'fan')
      this.$bus.emit('busFan', 'fan')

      setTimeout(function () {
        zh_tran('t')
      }, 800)
    },

    toJan() {
      this.isFan = false
      sessionStorage.setItem('cnType', 'jian')
      this.$bus.emit('busFan', 'jian')
      setTimeout(function () {
        zh_tran('s')
      }, 800)
    },

    init() {
      if (this.isLogin) {
        getInfo().then((res) => {
          this.brokerCode = res.data.member.broker_code
        })
        // this.asyncSetMessage()
      }
    },
    lang_change() {
      let url = [
        {
          url: 'aboutus/news/detail',
          replace: 'aboutus/news',
        },
        {
          url: 'aboutus/activity/Detail',
          replace: 'aboutus/activity',
        },
        {
          url: 'aboutus/blog/Detail',
          replace: 'aboutus/blog',
        },
      ]

      const index = url.findIndex((item) =>
        window.location.href.includes(item.url)
      )
      if (index !== -1) {
        let href = window.location.href.replace('zhcn', 'en')
        const index2 = href.indexOf(url[index].url)
        href = href.slice(0, index2) + url[index].replace
        window.location.replace(href)
      } else {
        window.location.replace(window.location.href.replace('zhcn', 'en'))
      }
    },
    change() {
      this.token = sessionStorage.getItem('token')
      this.email = sessionStorage.getItem('email')
      this.categ = sessionStorage.getItem('categ')
      this.personalok = sessionStorage.getItem('personalok')
      if (this.personalok == 1 || this.personalok == 0) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },

    logout() {
      this.$confirm('确认要退出吗？', {
        confirmButtonClass: 'confirm',
      })
        .then((_) => {
          sessionStorage.setItem('token', '')
          sessionStorage.setItem('email', '')
          sessionStorage.setItem('personalok', -1)
          sessionStorage.setItem('state_ai', -1)
          this.isLogin = false
          this.$router.push({
            path: '/login',
          })
        })
        .catch((_) => {})
    },

    gologin() {
      this.$router.push({
        path: '/login',
      })
    },
    goHome() {
      this.$router.push({
        path: '/my/home',
      })
    },
    goknowledge() {
      this.$router.push({
        path: '/knowledge',
      })
    },
    goTransaction() {
      this.$router.push({
        path: '/transaction',
      })
    },
    goindex() {
      this.$router.push({
        path: '/index',
      })
    },
    goinvest() {
      this.$router.push({
        path: '/investList',
      })
    },
    goreg() {
      this.$router.push({
        path: '/reg',
      })
    },
    move() {
      this.fill = '#3698f8'
    },
  },
  computed: {
    ...mapState(['total_count', 'unReadMessage']),
  },
  watch: {},
}
</script>

<style lang="less" scoped>
.fixTop {
  position: fixed;
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  z-index: 1000;
}

.safeW {
  width: 1600px;
  margin: 0 auto;
  position: relative;
}

.tL {
  font-size: 20px;
  color: #333;
  position: absolute;
  left: 0;
  top: 0;

  .tLflex {
    margin-left: 20px;
    height: 80px;
    display: flex;
    align-items: center;
    .l {
      > img {
        margin-right: 24px;
        width: 236px;
      }
    }
    .r {
      > span {
        cursor: pointer;
        font-weight: bold;
        margin-right: 75px;
        &.hit {
          color: #ce0d1b;
        }
      }
    }

    .r2 {
      > span {
        cursor: pointer;
        font-weight: bold;
        margin-right: 45px;
        &.hit {
          color: #ce0d1b;
        }
      }
    }
  }
}

.tR {
  position: absolute;
  right: 0;
  top: 0;
  .reg {
    width: 114px;
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
  }

  .flex {
    display: flex;
    align-items: center;
    margin-top: 13px;
    .l {
      font-size: 20px;
      color: #333;
      cursor: pointer;
      position: relative;
      margin-right: 30px;
      > span {
        position: absolute;
        left: -25px;
        top: 3px;
      }
    }
    .m {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .r {
      color: #333;
      font-size: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .flexTwo {
    display: flex;
    align-items: center;
    margin-top: 26px;
    .l {
      font-size: 20px;
      color: #333;
      cursor: pointer;
      position: relative;
      margin-right: 30px;
      > span {
        position: absolute;
        left: -25px;
        top: 3px;
      }
    }
    .m {
      img {
        width: 50px;
        height: 50px;
      }
    }
    .r {
      color: #333;
      font-size: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .reg2 {
    position: absolute;
    right: 10px;
    top: 20px;
    cursor: pointer;
    width: 100px;
    color: #333;
    font-size: 20px;
    text-decoration: underline;
  }

  .login {
    position: absolute;
    right: 150px;
    top: 28px;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    width: 50px;
  }

  .lang {
    position: absolute;
    right: 230px;
    top: 28px;
    font-size: 20px;
    white-space: nowrap;
    color: #333;
    cursor: pointer;
    > span {
      position: absolute;
      left: -25px;
      top: 3px;
      > img {
        width: 19px;
      }
    }
  }
}

.bigLogo {
  position: absolute;
  // left: -260px;
  top: 23px;
  > img {
    width: 236px;
  }
}
.fs-23 {
  font-size: 23px;
  margin-right: 15px;
}
.fs-20 {
  font-size: 20px;
}
.chat {
  box-sizing: border-box;
  margin-right: 20px;
  width: 50px;
  height: 50px;
  background: #f4f4f4;
  text-align: center;
  border-radius: 50%;
  padding-top: 10px;
}
.name {
  font-size: 20px;
  font-weight: 500;
  color: #2f2f2f;
}
.info {
  font-size: 16px;
  font-weight: 400;
  color: #bebebe;
}
.droItem {
  height: 50px;
  line-height: 50px;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 13px;
  }
  span {
    font-size: 20px;
    vertical-align: middle;
    font-weight: 400;
    color: #2f2f2f;
  }
}
::v-deep.badge .el-badge__content.is-fixed {
  top: 12px;
  right: 22px;
}
.messageBox {
  width: 450px;
  padding: 14px;
  padding-bottom: 5px;
}
.el-icon-setting {
  cursor: pointer;
  font-size: 17px;
}
.fiveMsgList {
  margin-top: 26px;
  .item {
    cursor: pointer;
    background: #f3f5fb;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    .content {
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      font-size: 14px;
      color: #bebebe;
    }
  }
}
.logoBox {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  img {
    width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.readMore {
  text-align: end;
  font-size: 16px;
  color: #6b89e7;
  text-decoration: underline;
  cursor: pointer;
}

.img50 {
  width: 50px;
}

.lang19 {
  width: 19px;
  margin-right: 5px;
}

.setimg {
  width: 23px;
}

.fileimg {
  width: 19px;
}

.shareimg {
  width: 25px;
}

.usdimg {
  width: 119px;
}

.helpimg {
  width: 24px;
}

.outimg {
  width: 22px;
}

.emailcss {
  font-size: 20px;
  padding-right: 30px;
}

.ft {
  position: absolute;
  right: 350px;
  top: 28px;
  width: 100px;
  font-size: 20px;
  cursor: pointer;
}

.ft2 {
  margin-right: 30px;
}

.regtxt {
  position: absolute;
  left: 0;
  top: 4px;
  width: 100%;
  text-align: center;
  color: #fff;
  background: #ce0d1b;
  border-radius: 6px;
  font-size: 24px;
}

.jianti {
  width: 80px;
  vertical-align: middle;
}

.flexl2 {
  width: 120px;
}
.img30 {
  width: 52px;
}
.langu {
  vertical-align: middle;
  font-size: 22px;
}
.el-dropdown-menu__item:not(.is-disabled):hover {
  //鼠标悬停样式
  color: #ce0d1b;
}
.el-icon-arrow-down {
  position: relative;
  top: -3px;
}
.img100 {
  width: 100px;
}
.cmbi {
  margin-right: 50px;
  font-size: 25px;
  font-weight: 500;
}
</style>
