<template>
  <div>
    <!-- <div class="fixBottom1">
      <div class="bwhite">
        <div class="blogo" @click="goindex">
          <img src="../assets/images/xuexi/logo.png" />
        </div>
        <div class="blogodesc">四重安全体系保护的<br />数字证券投资平台</div>
        <div class="blianxibtn">
          <img src="../assets/images/xuexi/lianxi.png" @click="gotuite" />
        </div>
        <div class="bshuxian"></div>
        <div class="bshuxian2"></div>
        <div class="faq" @click="goknowledge">
          <img src="../assets/images/index/faq.png" />
        </div>
        <div class="bshu1">
          <div class="shutitle">关于我们</div>
          <div class="shuContent">
            <a
              @click="
                $router.push({
                  name: 'news',
                })
              "
              >新闻发布</a
            >
            <a
              @click="
                $router.push({
                  name: 'job',
                })
              "
              >招聘</a
            >
            <a
              @click="
                $router.push({
                  name: 'blog',
                })
              "
              >博客</a
            >
          </div>
        </div>
        <div class="bshu4">
          <div class="shutitle">&nbsp;</div>
          <div class="shuContent">
            <a
              @click="
                $router.push({
                  name: 'activity',
                })
              "
              >活动</a
            >
          </div>
        </div>
        <div class="bshu2">
          <div class="shutitle">法律问题</div>
          <div class="shuContent">
            <a
              target="_blank"
              :href="href + 'file/20231219SgtoxPlatformTermsAndConditions.pdf'"
              >条款和条件</a
            >
            <a
              target="_blank"
              :href="href + 'file/20231215SgtoxPrivacyPolicy.pdf'"
              >隐私策略</a
            >
            <a
              target="_blank"
              :href="href + 'file/20231215SgtoxCookiesPolicy.pdf'"
              >Cookie政策</a
            >
            <a
              target="_blank"
              :href="href + 'file/20231215SgtoxExchangeRules.pdf'"
              >Exchange Rules</a
            >
          </div>
        </div>

        <div class="bshu3">
          <div class="shutitle">&nbsp;</div>
          <div class="shuContent">
            <a
              target="_blank"
              :href="href + 'file/20231215SgtoxListingRules.pdf'"
              >上市规则</a
            >
            <a href="#">网络安全提示</a>
            <a
              target="_blank"
              :href="href + 'file/20231215SgtoxRegulatoryDisclosures.pdf'"
              >监管信息披露</a
            >
            <a
              target="_blank"
              :href="href + 'file/20231215CommunityChannelsT&C.pdf'"
              >Community Channel</a
            >
          </div>
        </div>
      </div>
    </div> -->

    <div class="fixBottom2">
      <div class="bblue">
        Singapore Asset Token Exchange PTE. LTD. 新加坡通证资产交易所<br />
        @All rights reserved 2023 Level 11, Marina Bay Financial Center Tower 1,
        8 Marina Blvd, 018981 Singapore
      </div>
    </div>

    <!-- <div class="fixCookie" v-if="cookieShow">
      <div class="safeW">
        <div class="tiaokuantxt">
          一经使用本网站，您即同意<a
            target="_blank"
            :href="href + 'file/20231215SgtoxPrivacyPolicy.pdf'"
            >隐私权政策</a
          >和我们的<a
            target="_blank"
            :href="href + 'file/20231215SgtoxCookiesPolicy.pdf'"
            >使用条款</a
          >
        </div>
        <div class="tongyi" @click="tongyi">同意</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'NavButton',

  data() {
    return {
      cookieShow: true,
      href: window.location.href.split('#')[0],
    }
  },

  mounted() {
    if (localStorage.getItem('agree') == 1) {
      this.cookieShow = false
      $('.fixBottom2>.bblue').height(80)
    } else {
      this.cookieShow = true
    }
  },

  methods: {
    tongyi() {
      localStorage.setItem('agree', 1)
      this.cookieShow = false
      $('.fixBottom2>.bblue').height(80)
    },

    goknowledge() {
      this.$router.push({
        path: '/knowledge',
      })
    },
    goindex() {
      this.$router.push({
        path: '/index',
      })
    },
    gotuite() {
      window.open('https://x.com/sgtox_official?s=21&t=z68YoX8caCt_mqTQ-_-8XA')
    },
  },
}
</script>

<style lang="less" scoped>
.fixBottom1 {
  width: 100%;
  background: #fff;
  .bwhite {
    width: 1200px;
    margin: 0 auto;
    height: 363px;
    position: relative;
  }
}

.fixBottom2 {
  width: 100%;
  background: #da3c3f;
  .bblue {
    width: 1200px;
    margin: 0 auto;
    height: 113px;
    line-height: 20px;
    position: relative;
    font-size: 14px;
    color: #fff;
    padding-top: 30px;
  }
}

.blogo {
  position: absolute;
  left: 10px;
  top: 50px;
  cursor: pointer;
  > img {
    width: 167px;
  }
}

.blogodesc {
  position: absolute;
  left: 10px;
  top: 100px;
  font-size: 14px;
  color: #2f2f2f;
}

.blianxibtn {
  position: absolute;
  left: 10px;
  top: 190px;
  > img {
    cursor: pointer;
    width: 170px;
  }
}

.bshu1 {
  position: absolute;
  left: 480px;
  top: 50px;
}

.bshu2 {
  position: absolute;
  left: 850px;
  top: 50px;
}

.bshu3 {
  position: absolute;
  left: 1000px;
  top: 50px;
}

.bshu4 {
  position: absolute;
  left: 630px;
  top: 50px;
}

.shutitle {
  height: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin-bottom: 30px;
}

.shuContent {
  > a {
    display: block;
    color: #000;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 30px;
  }
}

.shuContent a:hover {
  text-decoration: underline;
}

.bshuxian {
  position: absolute;
  left: 750px;
  top: 50px;
  width: 1px;
  height: 200px;
  background: #bebebe;
}

.bshuxian2 {
  position: absolute;
  left: 400px;
  top: 50px;
  width: 1px;
  height: 200px;
  background: #bebebe;
}

.faq {
  position: absolute;
  left: 230px;
  top: 50px;
  cursor: pointer;
  > img {
    width: 82px;
  }
}

.fixCookie {
  position: fixed;
  z-index: 2000;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #c94946;
  .safeW {
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
}

.tiaokuantxt {
  position: absolute;
  left: 10px;
  top: 20px;
  color: #fff;
  font-size: 18px;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.tongyi {
  position: absolute;
  right: 0px;
  top: 13px;
  width: 80px;
  height: 35px;
  line-height: 35px;
  background: #fff;
  text-align: center;
  font-size: 18px;
  color: #c94946;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.lianxitxt {
  position: absolute;
  left: 32px;
  top: 3px;
  width: 100%;
  color: #fff;
}
</style>
