import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/login',
  },
  {
    path: '/index',

    redirect: '/login',
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
  {
    path: '/forgetpwdemail',
    name: 'forgetpwdemail',
    component: () => import('@/views/forgetpwdemail.vue'),
  },
  {
    path: '/forgetpwd',
    name: 'forgetpwd',
    component: () => import('@/views/forgetpwd.vue'),
  },
  {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/views/logincode.vue'),
  },
  {
    path: '/forgetpwdemailstatic',
    name: 'forgetpwdemailstatic',
    component: () => import('@/views/forgetpwdemailstatic.vue'),
  },
  {
    path: '/my/home',
    name: 'my',
    component: () => import('@/views/My'),
    children: [
      {
        path: '/my/home',
        name: 'home',
        component: () => import('@/views/My/home.vue'),
      },

      {
        path: '/my/fileList',
        name: 'fileList',
        component: () => import('@/views/My/fileList.vue'),
      },

      {
        path: '/my/wallet',
        name: 'wallet',
        component: () => import('@/views/My/wallet.vue'),
      },
      {
        path: '/my/subwallet',
        name: 'subwallet',
        component: () => import('@/views/My/subwallet.vue'),
      },

      {
        path: '/my/myInvest',
        name: 'myInvest',
        component: () => import('@/views/My/myInvest.vue'),
      },
      {
        path: '/my/submyInvest',
        name: 'submyInvest',
        component: () => import('@/views/My/submyInvest.vue'),
      },
      {
        path: '/my/investedDetail',
        name: 'investedDetail',
        component: () => import('@/views/investedDetail.vue'),
      },

      {
        path: '/my/rjpay',
        name: 'rjpay',
        component: () => import('@/views/My/rjpay.vue'),
      },
      {
        path: '/my/rjbank',
        name: 'rjbank',
        component: () => import('@/views/My/rjbank.vue'),
      },

      {
        path: '/my/jydetail',
        name: 'jydetail',
        component: () => import('@/views/My/jydetail.vue'),
      },
      {
        path: '/my/subjydetail',
        name: 'subjydetail',
        component: () => import('@/views/My/subjydetail.vue'),
      },
      {
        path: '/my/addId',
        name: 'addId',
        component: () => import('@/views/My/addId.vue'),
      },
      {
        path: '/my/addIdOne',
        name: 'addIdOne',
        component: () => import('@/views/My/addIdOne.vue'),
      },
      {
        path: '/my/setting',
        name: 'setting',
        component: () => import('@/views/My/setting.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'hash',

  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
